import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkIntercept from 'components/linkIntercept/LinkIntercept';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  title: PropTypes.object,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

function ColoredLinksComponent({ title, links, isHotelPage, isCard }) {
  return (
    <Root>
      {title && isHotelPage && !isCard && <Title>{title}</Title>}
      <LinksContainer isHotelPage={isHotelPage} isCard={isCard}>
        {links.map(({ id, slug, color, name }) => (
          <Fragment key={id}>
            {isHotelPage && !isCard ? (
              <Fragment>
                <LinkIntercept to={slug}>
                  <LinkStyle isHotelPage={isHotelPage} color={color}>
                    <LinkText isHotelPage={isHotelPage}>{name}</LinkText>
                    <FontAwesomeIcon icon='external-link-alt' />
                  </LinkStyle>
                </LinkIntercept>
                <Margin bottom='0.5' />
              </Fragment>
            ) : (
              <Fragment>
                <LinkStyle isHotelPage={isHotelPage} isCard={isCard} color={color}>
                  <LinkText isHotelPage={isHotelPage} isCard={isCard}>
                    {name}
                  </LinkText>
                </LinkStyle>
                <Margin bottom='0.5' />
              </Fragment>
            )}
          </Fragment>
        ))}
      </LinksContainer>
    </Root>
  );
}

ColoredLinksComponent.propTypes = propTypes;

export default ColoredLinksComponent;

const Root = styled.div`
  user-select: none;
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: 24px;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 2.8em;
  color: ${({ theme }) => theme.colors.text.templates.header};
`;

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  a {
    width: 48%;
    margin-bottom: 10px;
    margin-right: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '2px' : 'auto')};
  }

  ${mediaQueries.fromAndAbove.desktop`
    display: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? 'block' : 'flex')};  
    flex-wrap: wrap;
    a {
      width: auto;
      width: initial;
    }
  `}

  ${mediaQueries.fromAndBelow.tablet`
    margin-top: 6px;
  `}
`;

const LinkStyle = styled.div`
  ${flexbox({
    justifyContent: 'space-between',
  })}

  align-items: center;
  height: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '32px' : '22px')};
  padding: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '8px 13px' : '4px 6px')};
  background: ${({ theme, color }) => (color ? color : theme.colors.adjacent.default)};
  background-position: center;
  border-radius: 4px;
  cursor: pointer;

  svg {
    opacity: 0.4;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text.white};
  }
`;

const LinkText = styled.span`
  font-size: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '1.2em' : '1em')};
  font-weight: 800;
  line-height: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '1.5px' : '1.2px')};
  letter-spacing: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '0.5px' : '0.2px')};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.white};
  white-space: nowrap;
`;
