import React from 'react';
import axis from 'axis.js';
import { getOrCreateStore } from 'state/storeProvider';
import LinkIntercept from 'components/linkIntercept/LinkIntercept';
import CarouselImage from 'components/carousel/CarouselImage';
import PackageCard from './PackageCardComponent';
import HotelCardComponent from './HotelCardComponent';
import PackageWrap from './components/PackageWrap';
import getImageSource from './utils/getImageSource';
import getImageUniqueKey from './utils/getImageUniqueKey';
import getPackageLink from './utils/getPackageLink';
import { getIsPackageDirectFlight } from './utils/getIsPackageDirectFlight';
import getPackageUniqueKey from './utils/getPackageUniqueKey';
import { SAVED_PACKAGES_PACKAGE, RECOMMENDED_PACKAGES_PACKAGE, POPULAR_HOTELS_PACKAGE } from './constants/packageTypes';
import { Special_Offer } from 'state/searchResults/constants/deepLinkSpecialOfferConstants';
import { HOTEL_ONLY } from 'constants/selectValues';
import logHotelSlugNotProvided from 'helpers/errors/logHotelSlugNotProvided';
import { isMobile as getIsMobile } from 'utils/isMobile';

export function createPackageCards({
  packages,
  hotels,
  possibleHotelFacilities,
  packageType,
  configuration = {},
  data = {},
  events = {},
  showShareIcon = false,
}) {
  const {
    small = false,
    maxWidth,
    wrapPackage = false,
    target = '_blank',
    marginBottom,
    linkInterceptClassname = '',
    imgConfig = false,
  } = configuration;
  const { selectedRooms, saved = false, uniqueKeyHelp } = data;
  const { onMouseEnter, onMouseLeave, onClickAddHearted, onClickRemoveHearted, onClickTrackEvent } = events;
  const linkStyle = {
    display: 'block',
    margin: '0',
  };

  const store = getOrCreateStore();
  const { settings, urlSearchQuery, currentPageComponent } = store.getState();

  const airlineWhitelabel = settings.value && settings.value.airlineWhitelabel;

  const isCenteredLayoutSearchPage =
    settings.value.customSettings && settings.value.customSettings.isCenteredLayoutSearchPage;

  const isSearchPage = currentPageComponent.isSearchResults;
  const isMobile = getIsMobile();

  return packages.map((packet, index) => {
    let hotel = hotels ? hotels[packet.hotelId] : packet.hotel;

    if (POPULAR_HOTELS_PACKAGE === packageType) {
      hotel = packet;
    }

    //TODO : remove later - old route redirect for hotel page
    if (!hotel.hotelSlug) {
      logHotelSlugNotProvided(hotel.id);
      return null;
    }

    const packageIndex = hotel.package && hotel.package.index ? hotel.package.index : index;
    let packageUniqueKey = packet.packageUniqueKey
      ? packet.packageUniqueKey
      : getPackageUniqueKey(packageType, hotel.id, packageIndex, uniqueKeyHelp);

    if (SAVED_PACKAGES_PACKAGE === packageType) {
      packageUniqueKey = packet.packageUniqueKey;
      packet = packet.packet;
    }

    const hotelImages =
      hotel.images &&
      hotel.images.map((image, imageIndex) => (
        <CarouselImage
          key={getImageUniqueKey(packageUniqueKey, imageIndex)}
          src={getImageSource(image.source ? image.source : image, { small, imgConfig })}
          small={small}
        />
      ));

    let packageLink =
      POPULAR_HOTELS_PACKAGE !== packageType
        ? getPackageLink(
            hotel.hotelSlug,
            {
              origin: packet.airport ? packet.airport.code : HOTEL_ONLY,
              dates: packet.dates,
              roomConfigurations: selectedRooms ? selectedRooms : [{ numberOfAdults: 2, childrenAges: [] }],
              packageRoomId: packet.room.id,
              packagePrice: packet.price,
              packageBoardCode: packet.board.code,
            },
            undefined,
            urlSearchQuery.value
          )
        : `/c${hotel.hotelSlug}`;

    if (packet.highlighted) {
      packet.specialOffer = Special_Offer;
    }

    // For popular hotel card we use destination, for packages-search we use city
    let hotelCity = hotel.location && hotel.location.city;
    if (packageType === POPULAR_HOTELS_PACKAGE) {
      hotelCity = (hotel.location && hotel.location.destination) || hotelCity;
    } else {
      hotelCity = hotelCity || packet.destination.name;
    }

    const hotelScore = hotel.score ? hotel.score / 10 : packet.hotelScore / 10;
    // Travel Safe disabled for now
    // const travelSafe = hotel.facilities && hotel.facilities.includes(HEALTHY_AND_SAFETY_PROTOCOL);
    const travelSafe = false;
    const distance = hotel.location && hotel.location.distance;

    let facilities;
    if (hotel.facilitiesInfo) {
      facilities = hotel.facilitiesInfo.slice(0, 8);
    } else {
      facilities =
        possibleHotelFacilities && hotel.facilities
          ? possibleHotelFacilities.filter(({ id }) => hotel.facilities.includes(id)).slice(0, 8)
          : [];
    }

    let themeIds;
    if (hotel.themeIds) {
      themeIds = hotel.themeIds;
    } else {
      themeIds = hotel.themes ? hotel.themes : [];
    }

    const savedPackage = {
      packageUniqueKey,
      packageLink,
      selectedRooms,
      hotel,
      packet: POPULAR_HOTELS_PACKAGE !== packageType ? packet : {},
      packageType,
    };

    let hotelDescription = hotel.shortDescription ? hotel.shortDescription : '';

    const isDirectFlight = getIsPackageDirectFlight(packet.flightInfo, packageType);

    const CardComponent = POPULAR_HOTELS_PACKAGE !== packageType ? PackageCard : HotelCardComponent;

    const cardProps = {
      cardId: packageUniqueKey,
      packageKey: packageUniqueKey,
      carouselImages: hotelImages,
      hotelName: hotel.name,
      hotelCity,
      hotelStars: parseFloat(hotel.stars),
      hotelPrice: packet.price ? packet.price.toString() : null,
      hotelDescription,
      hotelScore,
      travelSafe,
      facilities,
      themeIds,
      distance,
      reviewsCount: hotel.reviewsCount,
      packageDates: RECOMMENDED_PACKAGES_PACKAGE !== packageType ? packet.dates : undefined,
      packageBoard: packet.board && packet.board.name,
      packageRoomType: packet.room && packet.room.name,
      packageDeparture: packet.airport && packet.airport.name,
      saved,
      subheader: true,
      priceBanner: false,
      specialOffer: packet.specialOffer,
      small,
      maxWidth,
      marginBottom,
      showDrawer: isSearchPage,
      airlineWhitelabel,
      isDirectFlight,
      co2Compensation: settings.value && settings.value.co2Compensation,
      imgConfig,
      isMobile,
      isCenteredLayoutSearchPage,
      onMouseEnter: axis.isFunction(onMouseEnter)
        ? () => {
            onMouseEnter(packet.hotelId);
          }
        : undefined,
      onMouseLeave,
      onClickRemoveHearted: axis.isFunction(onClickRemoveHearted)
        ? () => onClickRemoveHearted(packageUniqueKey)
        : undefined,
      onClickAddHearted: axis.isFunction(onClickAddHearted) ? () => onClickAddHearted(savedPackage) : undefined,
      showShareIcon,
      packageLink,
      isSearchPage,
    };

    const handleOnClickTrackEvent = axis.isFunction(onClickTrackEvent)
      ? () => onClickTrackEvent({ name: hotel.name, price: packet.price, dates: packet.dates })
      : undefined;

    const packageComponent = (
      <LinkIntercept
        key={packageUniqueKey}
        to={packageLink}
        target={target}
        config={{
          skipOnClickHandler: true,
          style: linkStyle,
        }}
        linkInterceptClassname={linkInterceptClassname}
        onClickTrackEvent={handleOnClickTrackEvent}
      >
        <CardComponent {...cardProps} />
      </LinkIntercept>
    );

    if (wrapPackage) {
      return <PackageWrap key={packageUniqueKey}>{packageComponent}</PackageWrap>;
    }

    return packageComponent;
  });
}
