import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import b from 'i18n/richTextFormats/b';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  reviewsCount: PropTypes.number.isRequired,
};

function ReviewsCount({ reviewsCount, hide, style }) {
  return (
    <Root hide={hide} style={style}>
      <FormattedMessage
        id='packageScore.reviewsCount'
        defaultMessage='Ratings based on <b>{reviewsCount}</b> reviews'
        description='Ratings based on <b>{reviewsCount}</b> reviews'
        values={{
          b,
          reviewsCount,
        }}
      />
    </Root>
  );
}

ReviewsCount.propTypes = propTypes;

export default ReviewsCount;

const Root = styled.span`
  display: ${({ hide }) => (hide ? 'none' : 'inline-block')};
  max-width: 120px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.text.default};
  font-weight: 100;
  padding: 6px 8px 5px;
  border-radius: 2px;
  line-height: 1.2;
  font-size: 10px;
  text-align: right;
  user-select: none;
  min-width: 48px;

  ${mediaQueries.fromAndBelow.l`
     max-width: 100px;
  `}

  @media screen and (max-width: 1490px) and (min-width: 1280px) {
    display: none;
  }

  ${mediaQueries.fromAndBelow.desktop`
    display: none;
  `}
`;
